import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import Listings from '../../components/Listings';
import { searchCategory, searchBrand, selectCategory, getCategories, getBrands } from '../../actions/AppActions';

import './style.scss';

function ListingsPage({
	selected_category,
	brands,
	categories,
	selectCategory,
	searchCategory,
	searchBrand,
	getCategories,
	getBrands
}) {
	let location = useLocation();
	let { id } = useParams();
	
	useEffect(() => {
		if (!id || !categories.length || !brands.length) {
			if (!categories.length) {
				getCategories();
			}
			if (!brands.length) {
				getBrands();
			}
			return;
		}
		switch (id) {
			case 'favorites':
				selectCategory(id);
				break;
			case 'top':
				selectCategory(id);
				searchCategory(id);
				break;
			default:
				console.log(location);
				if (location.pathname.includes('/brands')) {
					var item = brands.find((el) => el.id == id);
					searchBrand(item);
				} else {
					var item = categories.find((el) => el.id == id);
					searchCategory(item);
				}
				selectCategory(item);

				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories, brands, id]);

	return <div className="toolbar">{selected_category && <Listings />}</div>;
}

const mapStateToProps = (state) => {
	return {
		selected_category: state.app.selected_category,
		brands: state.app.listedBrands,
		categories: state.app.listedCategories,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	searchCategory,
	searchBrand,
	getCategories,
	getBrands
})(ListingsPage);
