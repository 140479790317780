import React, { useEffect, useState } from 'react';

import { Icon } from 'react-icons-kit';
import { heart } from 'react-icons-kit/fa/heart';
import { connect } from 'react-redux';
import pdf from '../../images/pdf2.png';

function Listing({ object, onFavoriteClick, favoriteIDs }) {
	const [isFavorite, setIsFavorite] = useState(false);

	var hanldeFavoriteClick = function () {
		setIsFavorite(!isFavorite);
		onFavoriteClick(object);
	};

	useEffect(() => {
		if (favoriteIDs.length) {
			if (favoriteIDs.indexOf(object.id) !== -1) {
				setIsFavorite(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favoriteIDs]);

	return (
		<div className="listing">
			<a
				target="_blank"
				href={`https://onlinemanualsapp.org/manual/${object.id}`}
				className="listing-img"
			>
				<img src={pdf} alt="" />
			</a>
			<a onClick={hanldeFavoriteClick} className={isFavorite ? 'favorite in' : 'favorite'}>
				<Icon icon={heart} />
			</a>
			<h4>
				<a target="_blank" href={`https://onlinemanualsapp.org/manual/${object.id}`}>
					{object.name}
				</a>
			</h4>
			<p>{object.category}</p>
			<p className="domain">{object.brand}</p>
		</div>
	);
}

var mapStateToProps = (state) => {
	return {
		favoriteIDs: state.app.favoriteIDs,
	};
};

export default connect(mapStateToProps, {})(Listing);
