import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import AmazonAds from '../../components/Listings/AmazonAds';

import './style.scss';

import { selectCategory, getCategories, getBrands } from '../../actions/AppActions';

function BrandsPage({ getCategories, getBrands, selectCategory, categories, brands }) {
	let location = useLocation();

	useEffect(() => {
		selectCategory(location.pathname.replace('/', ''));
		if (categories.length == 0) {
			getCategories();
		}
		if (brands.length == 0) {
			getBrands();
		}
	}, [location]);

	const RenderItems = () => {
		let items = location.pathname == '/brands' ? brands : categories;

		return Object.keys(items).map(function (key, i) {
			return (
				<div key={i} className="alphabet">
					<h2>{key}</h2>
					<div className="d-flex flex-wrap">
						{items[key].map((item, y) => {
							return (
								<p key={y}>
									<Link to={`${location.pathname}/${item.id}`}>{item.name}</Link>
								</p>
							);
						})}
					</div>
				</div>
			);
		});
	};

	return (
		<div className="toolbar">
			<div className="container listings">
				<AmazonAds />
				<RenderItems />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		categories: state.app.categories,
		brands: state.app.brands,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	getCategories,
	getBrands,
})(BrandsPage);
