import React from 'react';
import logo from '../../images/logo.png';
import './style.scss';

function Header() {	
	return (
		<header>
			<div className="container">
				<img style={{ marginLeft: 15 }} height={32} src={logo} />
			</div>
		</header>
	);
}

export default Header;
