import React, { useState, useEffect } from 'react';

function ProviderSelect({ selected, providers, onChange, placeholder }) {
	useEffect(() => {
		console.log(selected);
	}, [selected]);

	var hanldeChange = function (e) {
		if (!e.target.value) {
			onChange(null);
		} else {
			onChange(e.target.value);
		}
	};
	return providers.length > 0 ? (
		<select
			onChange={hanldeChange}
			defaultValue={selected ? selected.id : ''}
			className="custom-select"
		>
			<option value="">{placeholder}</option>
			{providers.map((prov, i) => (
				<option selected={prov.id == selected.id} key={i} value={prov.id}>
					{prov.name}
				</option>
			))}
		</select>
	) : (
		''
	);
}

export default ProviderSelect;
