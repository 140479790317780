import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ManualsForm from '../../components/ManualsForm';
import { selectCategory } from '../../actions/AppActions';

import './style.scss';

function Home({ selectCategory,darkMode, ...props }) {
	const history = useHistory();

	useEffect(() => {
		selectCategory(null);
	}, []);

	// Load Search Results
	var handleSearchSubmit = function (query) {
		history.push('/search?q=' + query);
	};

	return (
		<div
			style={
				props.showBg
					? props.bg
						? { backgroundImage: `url('${props.bg}')` }
						: { backgroundImage: `url('https://dash.onlinemanualsapp.org/background')` }
					: null
			}
			className={`toolbar ${props.showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}`}
		>
			<section className="main-content intro-page">
				<h1 className="text-center">Search Manuals, Start Now</h1>
				<ManualsForm
					defaultValue=""
					onSubmit={handleSearchSubmit}
					placeholder="Search a manual"
				/>
			</section>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
})(Home);
