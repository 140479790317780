import {
	GET_CATEGORIES,
	SELECT_CATEGORY,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
	SELECT_BRAND,
	GET_BRANDS,
	GET_TOP_BRANDS,
} from './types';
import axios from 'axios';
import Cookies from 'js-cookie';

const domain = 'https://dash.onlinemanualsapp.org';

export const getTopBrands = () => {
	return (dispatch) => {
		axios.get(domain + '/api/brands/top').then((res) => {
			dispatch({
				type: GET_TOP_BRANDS,
				payload: res.data,
			});
		});
	};
};

export const getCategories = () => {
	return (dispatch) => {
		axios.get(domain + '/api/categories').then((res) => {
			dispatch({
				type: GET_CATEGORIES,
				payload: res.data,
			});
		});
	};
};

export const getBrands = () => {
	return (dispatch) => {
		axios.get(domain + '/api/brands').then((res) => {
			dispatch({
				type: GET_BRANDS,
				payload: res.data,
			});
		});
	};
};

export const searchCategory = (value) => {
	return (dispatch, getState) => {
		dispatch({
			type: APP_SEARCH_RESULTS,
			payload: [],
		});

		if (value !== 'favorites') {
			dispatch({
				type: SEARCHING_RESULTS,
				payload: true,
			});
			axios
				.get(domain + '/api/search', {
					params: {
						category: value.id,
					},
				})
				.then((res) => {
					dispatch({
						type: APP_SEARCH_RESULTS,
						payload: res.data,
					});
				});
		}
	};
};
export const searchBrand = (value) => {
	return (dispatch, getState) => {
		dispatch({
			type: APP_SEARCH_RESULTS,
			payload: [],
		});

		if (value !== 'favorites') {
			dispatch({
				type: SEARCHING_RESULTS,
				payload: true,
			});
			axios
				.get(domain + '/api/search', {
					params: {
						brand: value.id,
					},
				})
				.then((res) => {
					dispatch({
						type: APP_SEARCH_RESULTS,
						payload: res.data,
					});
				});
		}
	};
};

export const loadMoreItems = (value, page, type) => {
	return (dispatch, getState) => {
		dispatch({
			type: SEARCHING_MORE_RESULTS,
			payload: true,
		});
		axios
			.get(domain + '/api/search', {
				params:
					type == 'brand'
						? {
								brand: value.id,
								page: page,
						  }
						: {
								category: value.id,
								page: page,
						  },
			})
			.then((res) => {
				dispatch({
					type: APP_SEARCH_MORE_RESULTS,
					payload: res.data,
				});
			});
	};
};

export const searchResults = (query) => {
	return (dispatch, getState) => {
		dispatch({
			type: APP_SEARCH_RESULTS,
			payload: [],
		});
		dispatch({
			type: SEARCHING_RESULTS,
			payload: true,
		});
		axios
			.get(domain + '/api/search', {
				params: {
					query: query,
				},
			})
			.then((res) => {
				dispatch({
					type: APP_SEARCH_RESULTS,
					payload: res.data,
				});
			});
	};
};

export const loadMoreResults = (query, page) => {
	return (dispatch, getState) => {
		dispatch({
			type: SEARCHING_MORE_RESULTS,
			payload: true,
		});
		axios
			.get(domain + '/api/search', {
				params: {
					query: query,
					page: page,
				},
			})
			.then((res) => {
				dispatch({
					type: APP_SEARCH_MORE_RESULTS,
					payload: res.data,
				});
			});
	};
};

export const selectCategory = (value) => {
	return {
		type: SELECT_CATEGORY,
		payload: value,
	};
};

export const selectBrand = (value) => {
	return {
		type: SELECT_BRAND,
		payload: value,
	};
};

export const addFavorite = (value) => {
	return (dispatch, getState) => {
		axios
			.post(domain + `/api/favorites/add`, {
				uid: getState().app.token,
				object: value.id,
			})
			.then((res) => {
				dispatch({
					type: ADD_FAVORITE,
					payload: res.data,
				});
			});
	};
};

export const getFavorites = () => {
	return (dispatch, getState) => {
		axios
			.get(domain + `/api/favorites`, {
				params: {
					uid: getState().app.token,
				},
			})
			.then((res) => {
				dispatch({
					type: ADD_FAVORITE,
					payload: res.data,
				});
			});
	};
};

// helper not action
export const getSingle = (id, callback) => {
	axios.get(domain + `/api/single/${id}`).then((res) => {
		callback(res.data);
	});
};

// helper not action
export const getReviews = (id, callback) => {
	axios.get(domain + `/api/single/${id}/reviews`).then((res) => {
		callback(res.data);
	});
};

// helper not action
export const getAutocompleteSuggestions = (query, callback) => {
	axios
		.get(domain + '/api/autocomplete', {
			params: {
				q: query,
			},
		})
		.then((res) => {
			callback(res.data);
		});
};

// Token is the only link between favorited manuals
// It's used to connect the extension with the main site
export const createAppToken = () => {
	return (dispatch) => {
		if (Cookies.get('app_token')) {
			var tkn = Cookies.get('app_token');
			// Refresh cookie
			var TokenPixel = new Image();
			TokenPixel.src = 'https://onlinemanualsapp.org/tkn.php?tkn=' + tkn;
			document.body.appendChild(TokenPixel);

			dispatch({
				type: SET_APP_TOKEN,
				payload: tkn,
			});

			dispatch(getFavorites());
		} else {
			axios.get(domain + '/api/token').then((res) => {
				// Refresh cookie
				var TokenPixel = new Image();
				TokenPixel.src = 'https://onlinemanualsapp.org/tkn.php?tkn=' + res.data;
				document.body.appendChild(TokenPixel);

				dispatch({
					type: SET_APP_TOKEN,
					payload: res.data,
				});
			});
		}
	};
};
