import {
	GET_CATEGORIES,
	SELECT_CATEGORY,
	GET_BRANDS,
	SELECT_BRAND,
	SEARCHING_RESULTS,
	SEARCHING_MORE_RESULTS,
	APP_SEARCH_RESULTS,
	APP_SEARCH_MORE_RESULTS,
	ADD_FAVORITE,
	SET_APP_TOKEN,
	GET_TOP_BRANDS,
} from '../actions/types';

const INITIAL_STATE = {
	topBrands: [],
	categories: [],
	brands: [],
	listedCategories: [],
	listedBrands: [],
	selected_category: null,
	selected_brand: null,
	searching: false,
	results: [],
	hasMore: false,
	page: 1,
	loadingMore: false,
	favorites: [],
	favoriteIDs: [],
	token: '',
};

const getArrayFromObject = (items) => {
	let keysArr = Object.keys(items);
	let listedItems = [];
	for (let i = 0; i < keysArr.length; i++) {
		for (let y = 0; y < items[keysArr[i]].length; y++) {
			listedItems.push(items[keysArr[i]][y]);
		}
	}
	return listedItems;
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
				listedCategories: getArrayFromObject(action.payload),
			};
		case SELECT_CATEGORY:
			return {
				...state,
				selected_category: action.payload,
			};
		case GET_BRANDS:
			return {
				...state,
				brands: action.payload,
				listedBrands: getArrayFromObject(action.payload),
			};
		case SELECT_BRAND:
			return {
				...state,
				selected_brand: action.payload,
			};
		case GET_TOP_BRANDS:
			return {
				...state,
				topBrands: action.payload,
			};
		case SEARCHING_RESULTS:
			return {
				...state,
				searching: true,
			};
		case SEARCHING_MORE_RESULTS:
			return {
				...state,
				loadingMore: true,
			};
		case APP_SEARCH_RESULTS:
			if (action.payload == undefined) {
				action.payload = [];
			}
			return {
				...state,
				searching: false,
				results: action.payload,
				hasMore: action.payload.length > 0,
				page: 1,
			};
		case APP_SEARCH_MORE_RESULTS:
			return {
				...state,
				loadingMore: false,
				results: [...state.results, ...action.payload],
				hasMore: action.payload.length > 0,
				page: state.page + 1,
			};
		case ADD_FAVORITE:
			return {
				...state,
				favorites: action.payload,
				favoriteIDs: action.payload.map((el) => el.id),
			};
		case SET_APP_TOKEN:
			return {
				...state,
				token: action.payload,
			};
		default:
			return state;
	}
};
