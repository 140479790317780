import React from 'react';
import { connect } from 'react-redux';

import './style.scss';

import List from './List';

import { Icon } from 'react-icons-kit';
import { chevronCircleUp } from 'react-icons-kit/fa/chevronCircleUp';
import { chevronCircleDown } from 'react-icons-kit/fa/chevronCircleDown';

import { setCollapsed } from '../../actions/PreferencesActions';

function Categories({ collapsed, setCollapsed, showBg, bg, darkMode, preferences }) {

	return (
		<section
			style={
				showBg
					? bg
						? { backgroundImage: `url('${bg}')` }
						: { backgroundImage: `url('../../images/bg.jpg)` }
					: null
			}
			className={`categories pt-3 pb-2${showBg ? ' bg' : ''}${darkMode ? ' dark' : ''}${
				collapsed ? ' collapsed' : ''
			}`}
		>
			<span
				className="collapse-icon"
				onClick={() => {
					setCollapsed();
				}}
			>
				{collapsed ? (
					<Icon size={40} icon={chevronCircleDown} />
				) : (
					<Icon size={40} icon={chevronCircleUp} />
				)}
			</span>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<p
							style={showBg ? (bg && darkMode ? { color: '#fff' } : null) : null}
							className="text-center mt-0 mb-1 text-uppercase"
						>
							Find Manuals, Select A Category.
						</p>
						<List favorites={true} />
					</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => {
	return {
		showBg: state.settings.options.background.visible,
		preferences: state.preferences.init,
		collapsed: state.preferences.collapsed,
		bg: state.settings.backgroundURL,
		darkMode: state.settings.darkMode,
	};
};

export default connect(mapStateToProps, {
	setCollapsed,
})(Categories);
