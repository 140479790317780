import React, { useEffect, useState } from 'react';

import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Categories from './components/Categories';

import Header from './components/Header';

import Home from './pages/Home/';
import ListingsPage from './pages/Listings/';
import BrandsPage from './pages/Brands/';
import SearchPage from './pages/Listings/Search';

import { createAppToken, getFavorites } from './actions/AppActions';
import { setPreferencesInit } from './actions/PreferencesActions';

import './App.scss';
import { connect } from 'react-redux';

function App({
	preferences,
	setPreferencesInit,
	token,
	createAppToken,
	getFavorites,
}) {
	const [display] = useState(localStorage.getItem('display_keep_it1') || '');

	useEffect(() => {
		if (display !== '' && !preferences) {
			setPreferencesInit(true);
		}
	}, [display]);

	useEffect(() => {
		if (token == '') {
			createAppToken();
		} else {
			getFavorites();
		}
	}, [token]);

	return (
		<Router>
			<Header />
			<Categories />
			<Switch>
				<Route path="/brands/:id">
					<ListingsPage />
				</Route>
				<Route path="/brands/">
					<BrandsPage />
				</Route>
				<Route path="/categories/:id">
					<ListingsPage />
				</Route>
				<Route path="/categories/">
					<BrandsPage />
				</Route>
				<Route path="/listings/:id">
					<ListingsPage />
				</Route>
				<Route path="/search">
					<SearchPage />
				</Route>				
				<Route path="/">
					<Home />
				</Route>
			</Switch>
		</Router>
	);
}

var mapStateToProps = (state) => {
	return {
		token: state.app.token,
		preferences: state.preferences.init,
	};
};

export default connect(mapStateToProps, {
	createAppToken,
	getFavorites,
	setPreferencesInit
})(App);
