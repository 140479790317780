import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Search from '../../components/Listings/Search';
import { searchCategory, selectCategory } from '../../actions/AppActions';

import './style.scss';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function SearchPage({ selected_category, categories, selectCategory, searchCategory, ...props }) {
	let query = useQuery();
	let q = query.get('q');

	return (
		<div className="toolbar">
			<Search query={q} />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		bg: state.settings.backgroundURL,
		showBg: state.settings.options.background.visible,
		selected_category: state.app.selected_category,
		categories: state.app.topCategories,
	};
};

export default connect(mapStateToProps, {
	selectCategory,
	searchCategory,
})(SearchPage);
