import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Category from './Category.js';
import Favorite from './Favorite.js';

import { getTopBrands } from '../../actions/AppActions';

function Categories({ topBrands, getTopBrands, ...props }) {
	const [mainBrands, setMainBrands] = useState([]);

	useEffect(() => {
		if (topBrands.length === 0) {
			getTopBrands();
		} else {
			var mainCats = topBrands;
			setMainBrands(mainCats);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topBrands]);

	return (
		<>
			{mainBrands.length ? (
				<div className="owl-theme-category">
					<OwlCarousel
						options={{
							nav: true,
							dots: false,
							rewind: false,
							slideBy: 4,
							navText: ['<span>‹</span>', '<span>›</span>'],
							responsive: {
								0: { items: 4 },
								768: { items: 6 },
								1200: { items: 10 },
							},
						}}
					>
						{props.favorites && <Favorite />}
						<Category
							cat={{
								name: 'Browse by Category',
								id: 'categories',
								PrimaryImage: require('../../images/icons/category.png'),
							}}
						/>
						<Category
							cat={{
								name: 'Browse by Brands',
								id: 'brands',
								PrimaryImage: require('../../images/icons/brand.png'),
							}}
						/>
						{mainBrands.map((cat, index) => (
							<Category cat={cat} key={index} />
						))}
					</OwlCarousel>
				</div>
			) : (
				<div className="loading"></div>
			)}
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		topBrands: state.app.topBrands,
		preferences: state.preferences.init,
	};
};

export default connect(mapStateToProps, {
	getTopBrands,
})(Categories);
